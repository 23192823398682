import * as React from 'react';
import cx from 'classnames';
import './index.scss';
import Title from '@/components/Title';

const list = [
  {
    title: 'Whitelist',
    content: 'Community will maintain a whitelist of assets & yield targets.'
  },
  {
    title: 'Stop Loss & Liquidation',
    content: 'We support user-specific stop loss policy & on-time liquidation.'
  },
  {
    title: 'SAFU Treasury',
    content: '10% of revenue will be in SAFU Treasury to cover tail risks.'
  }
];

export default function Security() {
  return (
    <div className='security'>
      <Title title='SECURITY' subTitle='' />
      <div className='security-content'>
        <ul className='security-list'>
          {
            list.map((i, index) => {
              return (
                <li className='security-list-item'>
                  <i className={cx('security-list-item-image', `security-list-item-image-${index + 1}`)}></i>
                  <h3>{i.title}</h3>
                  <p>{i.content}</p>
                </li>
              );
            })
          }
        </ul>
      </div>
    </div>
  );
}
