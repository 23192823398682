import * as React from 'react';
import cx from 'classnames';
import './index.scss';
import Title from '@/components/Title';

const list = [
  {
    title: 'Safe & Fast liquidation',
    subTitle: '',
    content: `Positive Liquidation Pool + Arbitrage interface to realize fast and secure liquidation.`
  },
  {
    title: 'Oracle Protection',
    subTitle: '',
    content: `Protect positions when price from different data feeds differs extremely.
Time Weighted Average Price (TWAP)`
  }
]

export default function Lever() {
  return (
    <div className='lever'>
      <Title title='LEVERAGE' subTitle='' />
      <div className='lever-content'>
        <ul className='strategy-list'>
          {
            list.map((i, index) => {
              return (
                <li className='strategy-list-item'>
                  <i className={cx('strategy-list-item-image', `leverage-image-${index + 1}`)} />
                  <h3>{i.title}</h3>
                  <h4>{i.subTitle}</h4>
                  <p>{i.content}</p>
                </li>
              );
            })
          }
        </ul>
      </div>
    </div>
  );
}
