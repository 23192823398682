import * as React from 'react';
import cx from 'classnames';
import './index.scss';
import Title from '@/components/Title';

const featureList = [
  {
    tag: '01',
    title: 'Long/Short Farming',
    content: `Open a Long/Short Postion through borrowing different asset (USDT or cryptocurrency)`
  },
  {
    tag: '02',
    title: 'Hedge Farming',
    content: `Be neutral from risk explosure by long + short hedging`
  },
  {
    tag: '03',
    title: 'Community Driven',
    content: `build by the community, for the community`
  },
  {
    tag: '04',
    title: 'More',
    content: `NFT bonding to positions will be release soon`
  }
]

export default function Feature() {
  return (
    <div className='feature'>
      <Title title='FEATURES' subTitle='' />

      <div className='feature-content content'>
        <ul className='feature-list'>
          {
            featureList.map(i => {
              return (
                <li className='feature-list-item'>
                  <b className='feature-list-item-tag'>{i.tag}</b>
                  <i className='feature-list-item-image'></i>
                  <h3>{i.title}</h3>
                  <p>{i.content}</p>
                </li>
              );
            })
          }
        </ul>
      </div>
    </div>
  );
}
