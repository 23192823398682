import * as React from 'react';
import cx from 'classnames';
import './index.scss';
import Title from '@/components/Title';

const list = [
  {
    title: 'Integrate Farming Pools on:',
    subTitle: 'O3Swap Flamingo',
    content: ``
  },
  {
    title: 'Lending Pools:',
    subTitle: 'BTC, ETH, Neo, FLM, O3...',
    content: ``
  }
]

export default function Strategy() {
  return (
    <div className='strategy'>
      <Title title='POOLS' subTitle='' />
      <div className='strategy-content'>
        <ul className='strategy-list'>
        {
          list.map((i, index) => {
            return (
              <li className='strategy-list-item'>
                <i className={cx('strategy-list-item-image', `strategy-list-item-image-${index + 1}`)} /> 
                <h3>{i.title}</h3>
                <h4>{i.subTitle}</h4>
                <p>{i.content}</p>
              </li>
            );
          })
        }
        </ul>
      </div>
    </div>
  );
}
