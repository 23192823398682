import * as React from 'react';
import cx from 'classnames';
import './index.scss';
import { Link } from 'react-router-dom';

export default function KV() {
  return (
    <div className='kv'>
      <div className='kv-inner'>
        <i className='kv-image' />
        <section className='kv-title'>
          <h1>{'POPLAR'.split('').map((char, index) => {
            return <b key={index}>{char}</b>;
          })}</h1>
          <p>
            {`The First Borrow + Farming Platform on Neo`.split(' ').map((word, index) => {
              return <span key={index}>{word} </span>;
            })}
          </p>
          <Link to='/app'>{'Learn More >'}<i className='icon-arrow'></i></Link>
        </section>

        <div className='kv-mouse'>
          <p>Scroll Down</p>
          <i />
        </div>
      </div>
    </div>
  );
}
