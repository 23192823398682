import * as React from 'react';
import './index.scss';
import { Link, NavLink } from 'react-router-dom';
import { createPortal } from 'react-dom';
import classNames from 'classnames';

export default function Nav() {
  const [fixed, setFixed] = React.useState(false);
  React.useEffect(() => {
    window.addEventListener('scroll', () => {
      if (document.documentElement.scrollTop > 0 && !fixed) {
        setFixed(true);
      } else if (document.documentElement.scrollTop === 0) {
        setFixed(false);
      }
    })
  }, []);

  return createPortal(
    <nav className={classNames('nav', {fixed})}>
      <div className='nav-inner'>
        <Link className='nav-logo' to='/'>
          <i></i>
          POPLAR
        </Link>

        <ul className='nav-list'>
          <li>
            <NavLink to='/' exact>HOME</NavLink>
          </li>
          {/* <li>
            <NavLink to='/docs' exact>DOCS</NavLink>
          </li> */}
          {/* <li>
            <a href='https://medium.com/@Francium-Defi' target='_blank'>BLOG</a>
          </li> */}
          {/* <li>
            <NavLink to='/app' exact>APP</NavLink>
          </li> */}
        </ul>
      </div>
    </nav>,
    document.body
  );
}
