import * as React from 'react';
import './index.scss';

export interface ITitleProps {
  title: string;
  subTitle?: string;
}

export default function Title(props: ITitleProps) {
  return (
    <div className='title'>
      <h2>{props.title}</h2>
      {props.subTitle && <p>{props.subTitle}</p>}
    </div>
  );
}
