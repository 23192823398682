import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { useEffect, useState } from 'react';
import './index.scss';

import Nav from '@/components/Nav';
import KV from './KV';
import Feature from './Feature';
import Strategy from './Strategy';
import Lever from './Lever';
import Security from './Security';
import Partners from './Partners';

import './index.scss';

export default function Home() {
  return (
    <div className='home'>
      <Nav />
      <KV />
      <Feature />
      <Strategy />
      <Lever />
      <Security />
      {/* <Partners /> */}
      <div className='copyright'>Copyright © 2021 Poplar Foundation</div>
    </div>
  );
};
