import React from 'react';
import './App.scss';
import Home from './pages/Home';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

function App() {
  return (
    <BrowserRouter basename=''>
      <Switch>
        <Route path='/' exact component={Home} />
        <Redirect to='/' />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
